import React from "react";
import LinkMaterial from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";


const ColorButton = ({ href, children, ...props }) => {
    const ButtonStyle = withStyles(() => ({
        root: {
            marginLeft: "1rem",
            marginTop: "10px !important",
            border: "1px solid #fafafa",
            color: "#000000",
            backgroundColor: "#8bb830",
            "&:hover, &:focus": {
                color: "#fafafa",
                backgroundColor: "#8bb830",
            },
            "&:focus": {
                backgroundColor: "#6c9025",
            },
            "&:after": {
                content: " \" >>>\"",
                marginLeft: "5px",
            },
        },
    }))(Button);

    return (
        <>
            <LinkMaterial className="colorButton" tabindex="-1" href={href} {...props}>
                <ButtonStyle
                    type={props.type}
                    variant="contained"
                    color="primary">
                    {children}
                </ButtonStyle>
            </LinkMaterial>
        </>
    );
};


export default ColorButton;
