/* @format */
import React from "react";
import globalStyles from "./styles";

import clsx from "clsx";

export default function InfoBox({ title, flexBox, icon, children }) {
    const classes = globalStyles();

    if (flexBox == true) {
        return (
            <div className={classes.flexBoxTrue}>
                <h3 className={classes.infoBoxTitle}>{title}</h3>
                <div className="infoBox">{children}</div>
            </div>
        );
    } else if (flexBox == false) {
        return (
            <div className={classes.flexBoxFalse}>
                {icon ? (
                    <div
                        className={clsx(
                            classes.flexBoxIconDiv,
                            classes.itemFlex
                        )}
                    >
                        {React.cloneElement(icon, {
                            style: {
                                width: "100%",
                                height: "auto",
                                display: "block",
                                margin: "auto"
                            }
                        })}
                    </div>
                ) : null}
                <div
                    className={clsx(
                        classes.infoBoxFalseInfo,
                        classes.itemFlexText
                    )}
                >
                    <h3 className={classes.infoBoxTitle}>{title}</h3>
                    <div className="infoBox">{children}</div>
                </div>
            </div>
        );
    }
}
