
import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export default function SchulIcon(props) {
    const style = {
        fill: "#6f6f6e",
        strokeWidth: 2.86291003
    };
    const style2 = {
        fill: "#fff",
        strokeWidth: 2.86291003
    };
    return (
        <SvgIcon viewBox="0 0 100 100" {...props }>
            <g
                id="layer1">
                <g
                    transform="matrix(0.26458333,0,0,0.26458331,13.44147,89.057423)"
                    id="g2738">
                    <g
                        transform="translate(-1.4020586,28.330487)"
                        id="g2738-3">
                        <g
                            transform="translate(0.02952108,-5.0986248)"
                            id="g2738-6">
                            <path
                                style={ style }
                                d="M 301.89371,-197.99556 147.8629,-331.96971 c -5.77865,-5.00999 -14.31852,-5.00999 -20.09716,0 l -154.030806,133.97415 c -6.43337,5.61465 -7.173492,15.46188 -1.651043,21.96912 5.550917,6.50722 15.28637,7.25585 21.7197401,1.66999 L 137.78586,-299.6063 281.76807,-174.35645 c 2.90357,2.53379 6.4903,3.77189 10.02012,3.77189 4.32687,0 8.65374,-1.84275 11.69962,-5.41309 5.57938,-6.53603 4.83927,-16.38326 -1.5941,-21.99791"
                                id="path2678" />
                            <path
                                style={ style }
                                d="m 142.85285,-277.75235 c -2.90357,-2.5338 -7.1735,-2.5338 -10.04859,0 L 16.718945,-176.48713 c -1.6795,1.46844 -2.675817,3.62792 -2.675817,5.90257 v 146.470339 c 0,4.290166 3.44441,7.774128 7.714343,7.774128 H 253.89963 c 4.24147,0 7.68589,-3.483962 7.68589,-7.774128 V -170.58456 c 0,-2.27465 -0.96785,-4.40534 -2.67583,-5.90257 z"
                                id="path2680" />
                            <path
                                style={ style2 }
                                d="m 154.1824,-86.854317 c 0.25621,-0.431896 0.56933,-0.74862 0.59779,-1.094137 0.14234,-1.526032 0.28467,-3.052064 0.2562,-4.606885 -0.11381,-6.88155 0.79706,-7.716551 6.26257,-10.883781 20.04024,-11.60361 29.14943,-35.3003 22.14673,-57.01028 -7.14502,-22.19945 -28.21004,-35.84737 -51.38155,-33.25599 -25.47728,2.82172 -44.777386,27.06548 -41.845367,52.80649 1.935702,17.04549 10.532517,29.82961 25.363427,38.09322 3.67214,2.04431 5.3801,4.578101 5.06698,8.695511 -0.11393,1.526028 -0.0569,3.080858 0.0569,4.60689 0.0569,0.921378 0.39853,1.813963 0.62625,2.850514 1.08172,0.172759 1.99264,0.460689 2.93202,0.460689 9.50773,0 18.98698,-0.02879 28.49471,-0.115173 0.48393,0.02879 0.99632,-0.345516 1.42331,-0.547068 m -16.42501,11.776366 c -6.06332,0 -12.15509,0 -18.24687,0 -1.33791,0 -2.67583,0.05759 -3.98526,-0.08638 -3.67214,-0.403103 -5.46552,-2.217067 -5.66479,-5.931373 -0.17079,-3.080859 -0.11381,-6.132923 -0.0569,-9.213782 0.0285,-1.900345 -0.62625,-3.339993 -2.30576,-4.290163 -0.99632,-0.57586 -1.90724,-1.2381 -2.84662,-1.90034 -20.125631,-14.108601 -29.604894,-38.841851 -24.082444,-62.711311 5.550917,-24.04221 25.249564,-42.03787 49.673584,-45.43546 30.91433,-4.31896 60.77541,17.82291 65.47235,49.0058 3.72907,24.70446 -4.9816,44.42771 -24.82257,59.227351 -0.76859,0.57586 -1.53717,1.12292 -2.36269,1.58361 -1.87877,1.03656 -2.6189,2.620168 -2.5335,4.750857 0.0854,2.101895 -0.11382,4.232582 0.0285,6.334476 0.42698,6.564819 -2.39117,9.041023 -8.88148,8.7243 -3.21668,-0.143965 -6.46183,-0.02879 -9.67852,-0.02879 -3.24515,-0.02879 -6.46182,-0.02879 -9.70697,-0.02879"
                                id="path2682" />
                            <path
                                style={ style2 }
                                d="m 115.98072,-49.596087 c 1.7649,-0.201551 3.04588,-0.431896 4.35533,-0.460688 11.58576,-0.02879 23.17152,-0.02879 34.78574,-0.02879 0.93939,0 1.90724,0.02879 2.84662,0.115173 1.25252,0.143965 2.19191,1.209308 1.85031,2.389824 -0.68319,2.15948 -1.28099,4.491719 -2.50503,6.334475 -1.22405,1.871549 -2.96049,3.541548 -4.8108,4.837235 -8.39754,6.017752 -21.17887,5.873786 -29.54794,-0.115172 -3.55829,-2.53379 -6.00638,-5.844992 -6.91729,-10.192746 -0.19927,-0.691034 -0.0569,-1.468446 -0.0569,-2.879307"
                                id="path2684" />
                            <path
                                style={ style2 }
                                d="m 137.98512,-57.370215 c -6.43338,0 -12.89521,0 -19.32858,0 -1.33792,0 -2.67582,0.02879 -3.98526,-0.143965 -2.98897,-0.46069 -4.83927,-2.648963 -4.78234,-5.470683 0.0569,-2.591376 1.76491,-4.606891 4.49767,-5.096373 1.11018,-0.201551 2.24883,-0.230345 3.38747,-0.230345 13.26527,0 26.53054,-0.02879 39.7958,0 1.50872,0 3.0459,0.115173 4.49767,0.489483 2.13497,0.518275 3.52982,2.389824 3.70061,4.578097 0.19926,2.303446 -1.19558,4.664477 -3.44441,5.35551 -1.42332,0.431897 -2.98896,0.547069 -4.4692,0.547069 -6.60416,0 -13.23681,-0.02879 -19.86943,-0.02879 z"
                                id="path2686" />
                            <path
                                style={ style2 }
                                d="m 187.97183,-98.717059 c 1.30945,0.57586 2.56195,0.77741 3.30208,1.49724 3.81447,3.7143 7.57201,7.457403 11.18723,11.37326 1.53718,1.641205 1.36638,3.714305 0.11381,5.211545 -1.39484,1.641205 -3.84293,2.044309 -5.57938,0.403104 -3.98526,-3.771893 -7.79974,-7.745336 -11.58576,-11.74757 -1.11017,-1.18052 -1.33791,-2.879309 -0.34158,-4.261379 0.71165,-0.97896 1.90723,-1.6412 2.90355,-2.4762"
                                id="path2688" />
                            <path
                                style={ style2 }
                                d="m 58.279654,-141.58994 c -2.277299,0 -4.554599,0.0576 -6.831899,-0.0288 -0.91092,-0.0288 -1.907237,-0.25913 -2.732749,-0.63345 -1.309448,-0.57586 -1.964179,-1.78517 -2.078041,-3.22483 -0.113815,-1.43965 0.91092,-3.16723 2.220369,-3.7143 0.512391,-0.23034 1.081713,-0.4031 1.651035,-0.4319 5.323192,-0.0288 10.646373,0 15.969565,0.0288 0.370052,0 0.740115,0.14397 1.110179,0.25914 1.423311,0.48948 2.476558,1.95792 2.561967,3.48396 0.08536,1.72759 -1.05326,3.45516 -2.73276,3.91585 -0.91092,0.23035 -1.878771,0.31673 -2.818157,0.34552 -2.078041,0.0576 -4.184536,0 -6.262578,0 v 0 z"
                                id="path2690" />
                            <path
                                style={ style2 }
                                d="m 203.82752,-208.53383 c -0.59779,1.32449 -0.79706,2.56259 -1.53717,3.31121 -3.67215,3.85828 -7.37276,7.68775 -11.24416,11.34447 -1.59412,1.52603 -3.70062,1.35327 -5.18087,0.0576 -1.62257,-1.46845 -1.99263,-3.88707 -0.34159,-5.64344 3.75755,-4.03103 7.68588,-7.88931 11.64269,-11.71879 1.16711,-1.12292 2.87509,-1.29568 4.21301,-0.34551 1.02478,0.71982 1.65103,1.95793 2.44809,2.99447"
                                id="path2692" />
                            <path
                                style={ style2 }
                                d="m 76.469577,-212.01778 c 0.569322,0.25913 1.793374,0.51827 2.533501,1.2381 3.700611,3.57034 7.287348,7.22706 10.845631,10.91257 0.512391,0.51828 0.882455,1.2669 1.081713,1.98672 0.45546,1.61241 0,3.02327 -1.22404,4.14621 -1.195587,1.03654 -2.818157,1.2093 -4.241468,0.46069 -0.341598,-0.17277 -0.711661,-0.34552 -0.967851,-0.60466 -3.757553,-3.77189 -7.515095,-7.515 -11.187241,-11.37326 -0.597787,-0.63345 -0.91092,-1.67 -1.053247,-2.59138 -0.313133,-2.15948 1.56565,-4.17499 4.213002,-4.17499"
                                id="path2694" />
                            <path
                                style={ style2 }
                                d="m 141.88498,-226.04001 c 0,2.30345 0.0569,4.5781 -0.0284,6.85275 -0.0284,0.92138 -0.22772,1.90035 -0.54086,2.76414 -0.54085,1.46844 -2.02109,2.33224 -3.58674,2.27465 -1.56564,-0.0576 -3.01742,-1.00775 -3.44441,-2.47621 -0.28467,-0.89258 -0.39853,-1.87154 -0.427,-2.79292 -0.0569,-4.37655 -0.0284,-8.78189 -0.0284,-13.15844 0,-0.37431 0,-0.7774 0.0569,-1.15171 0.31313,-2.5338 1.73644,-4.11742 3.81447,-4.23259 2.2773,-0.0864 3.89988,1.61242 4.18453,4.46293 0.0854,0.95017 0.0569,1.90034 0.0569,2.85052 0,1.52602 0,3.05205 0,4.57809 h -0.0569 z"
                                id="path2696" />
                            <path
                                style={ style2 }
                                d="m 91.101227,-94.138959 c -0.313132,0.63344 -0.626265,2.015506 -1.451776,2.908101 -3.359025,3.599129 -6.860366,7.054296 -10.333252,10.538258 -0.512391,0.518276 -1.224041,0.892585 -1.907237,1.180516 -1.451776,0.604654 -3.074346,0.172759 -4.127605,-1.007757 -1.110179,-1.266896 -1.337915,-2.706549 -0.683184,-4.232582 0.284655,-0.691033 0.740115,-1.353274 1.252518,-1.871549 3.302081,-3.397582 6.632629,-6.766367 9.991642,-10.106367 0.65473,-0.63344 1.537173,-1.15172 2.391162,-1.43965 2.220369,-0.80621 4.696927,0.97896 4.867732,4.03103"
                                id="path2698" />
                            <path
                                style={ style2 }
                                d="m 216.89352,-141.7627 c -2.64735,0 -5.32318,0.25914 -7.94208,-0.0576 -3.04588,-0.37431 -4.41226,-3.7719 -2.59042,-6.04655 0.65472,-0.80621 1.85031,-1.6124 2.81816,-1.64121 5.29471,-0.17275 10.6179,-0.20154 15.94108,-0.0576 2.50504,0.0576 3.98528,1.87156 3.92835,4.05983 -0.0569,2.13068 -1.56565,3.65672 -4.15607,3.82947 -2.64736,0.17277 -5.29473,0.0288 -7.97056,0.0288 -0.0284,-0.0288 -0.0284,-0.0576 -0.0284,-0.11517"
                                id="path2700" />
                            <path
                                style={ style2 }
                                d="m 97.733857,-147.86683 c 1.30945,-20.32791 16.965883,-37.14306 38.059353,-38.29478 0.93939,-0.0576 1.90724,0.0288 2.81816,0.23034 1.76491,0.37431 3.04589,1.95794 3.10282,3.62793 0.0854,1.69879 -1.08171,3.42638 -2.81815,3.82948 -1.45178,0.34552 -2.98895,0.4319 -4.49767,0.63345 -4.3838,0.51828 -8.53987,1.75637 -12.32588,4.05982 -8.82453,5.32672 -14.17618,13.04326 -15.79875,23.35118 -0.2562,1.49724 -0.31314,3.05206 -0.56934,4.5781 -0.42699,2.64895 -1.87877,4.08861 -3.9568,4.08861 -2.334233,0 -3.842953,-1.75637 -4.013743,-4.63568 -0.0285,-0.54707 0,-1.12293 0,-1.46845"
                                id="path2702" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}
