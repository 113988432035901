import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import globalStyles from "../components/styles";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import EntryLayout from "../components/EntryLayout";
import SEO from "../components/seo";
import Navigation from "../components/Navigation";

import InfoBar from "../components/InfoBar";
import InfoBox from "../components/InfoBox";
import InfoTitle from "../components/InfoTitle";


import ElternIcon from "../components/icons/ElternIcon.js";
import SchuleIcon from "../components/icons/SchuleIcon.js";
import LehrerIcon from "../components/icons/LehrerIcon.js";
import SecurityIcon from "@material-ui/icons/Security";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import LockIcon from "@material-ui/icons/Lock";

import ColorButton from "../components/ColorButton";

const IndexPage = () => {
    const classes = globalStyles();
    const [widthSize, setWidthSize] = useState();

    useEffect(() => {
        const widthFunktion = () => {
            const a = window.innerWidth;
            if( a >= 400){
                setWidthSize(false);
            }else{
                setWidthSize(true);
            }
        };

        window.addEventListener("resize", widthFunktion);

        return function cleanup() {
            window.removeEventListener("resize", widthFunktion);
        };

    }, []);

    return (
        <EntryLayout>
            <SEO title="Start" />
            <Navigation path="/"/>
            <InfoTitle type="1" />
            <InfoBar wide="darkBlue">
                <p  className={classes.textCenter}>
                    Beim Vorgehen gegen Schulabsentismus ist eines der wichtigsten
                    Instrumente <b> die frühzeitige Erkennung von Schulverweigerung. </b>
                    Genau da setzt die Software <b className={classes.fehlzeitenerfassung}> fehlzeitenerfassung.de </b> an:
                    Sie ermöglicht Lehrer/-innen und Sozialarbeiter/-innen Ursachen
                    und erste Anzeichen der Verweigerung effektiv zu erfassen und zu
                    erkennen, um adäquate Interventionsschritte einzuleiten. So kann
                    eine Chronifizierung der Verweigerung verhindert werden.
                    Außerdem verringert die Software den Verwaltungsaufwand der Schule
                    deutlich, indem sie die Effektivität im Umgang mit Schulverweigerung
                    steigert.
                </p>
                <div className={ classes.flexboxParent }>
                    <InfoBox title="Was wir bieten" flexBox={true}>
                        <ul>
                            <li className={classes.ULItem}> Früherkennung unterschiedlicher Verweigerungsmuster (entschuldigt, unentschuldigt, Verspätungen, Randstunden) </li>
                            <li className={classes.ULItem}> frühzeitige Intervention </li>
                            <li className={classes.ULItem}> Verlaufsgeschichte der Verweigerung in der Schule </li>
                            <li className={classes.ULItem}> Unterstützung systematischer Interventionen </li>
                            <li className={classes.ULItem}> Visualisierung von Fehlzeiten </li>
                            <li className={classes.ULItem}> Nutzung als elektronisches Klassenbuch. </li>
                        </ul>
                    </InfoBox>
                    <InfoBox title="Ihre Vorteile" flexBox={true}>
                        <ul>
                            <li className={classes.ULItem}> schnellere Interventionen durch systematische
                                Erfassung der Fehlzeiten </li>
                            <li className={classes.ULItem}> Zeitersparnis dank automatischer Aufsummierung der
                                Fehlzeiten für die Zeugnisse </li>
                            <li className={classes.ULItem}> Kontoauszug jedes Schülers mit Fehlzeiten und Interventionen
                                für die Schülerakte oder Behörden, wie das Jugendamt mit
                                wenigen Klicks erstellen </li>
                            <li className={classes.ULItem}> Flexibilität durch die Möglichkeit, die Daten auf Smartphones
                                und Tablets überall einzutragen und zu bearbeiten </li>
                            <li className={classes.ULItem}> schnell und einfach Analysen auf Klassen und/oder Schulebene
                                durchführen</li>
                            <li className={classes.ULItem}> erhöhte Effektivität durch drastische Minimierung des
                                Verwaltungsaufwands. </li>
                        </ul>
                    </InfoBox>
                </div>
                <p className={classes.textCenter}>
                    Unser Ziel ist es, so vielen Kindern und Jugendlichen wie möglich
                    zu einem Schulabschluss zu verhelfen. Über 60 Schulen in derzeit
                    18 Kommunen arbeiten zu diesem Zweck bereits mit unserer Software
                    fehlzeitenerfassung.
                </p>
                <ColorButton href="/app/" >
                    SOFORT STARTEN
                </ColorButton>
            </InfoBar>
            <InfoTitle type="2" />
            <InfoBar wide="white">
                <InfoBox title="Informationen für Schulen" flexBox={false} icon={ <SchuleIcon /> } >
                    {widthSize ? ""
                        :
                        <p> Informationen für Schulleiterinnen und Schulleiter.
                            Sie möchten die organisatorischen und pädagogischen Maßnahmen
                            zum Umgang mit Schulabsentismus kennenlernen. </p>
                    }

                    <Link to="/info/gezielteInformationen/schule/"
                        className={classes.link}>
                        MEHR ERFAHREN
                        <div className={classes.buttonLink}>
                            <KeyboardArrowRightIcon className={classes.linkIcon}/>
                        </div>
                    </Link>
                </InfoBox>
                <InfoBox title="Informationen für Lehrer" flexBox={false} icon={ <LehrerIcon /> }>
                    {widthSize ? ""
                        :
                        <p> Schulabsentismus ist ein Problem an Ihrer Schule und Sie
                            möchten sich über die pädagogischen und organisatorischen
                            Möglichkeiten zum Umgang informieren. </p>
                    }
                    <Link to="/info/gezielteInformationen/lehrer/"
                        className={classes.link}>
                        MEHR ERFAHREN
                        <div className={classes.buttonLink}>
                            <KeyboardArrowRightIcon className={classes.linkIcon}/>
                        </div>
                    </Link>
                </InfoBox>
                <InfoBox title="Informationen für Eltern" flexBox={false} icon={ <ElternIcon /> }>
                    {widthSize ? ""
                        :
                        <div> Sie interessieren sich für den Umgang mit Schulabsentismus,
                            oder <b className={classes.fehlzeitenerfassung}> fehlzeitenerfassung.de </b> wird bereits an der Schule Ihrer Kinder
                            eingesetzt. </div>
                    }
                    <Link to="/info/gezielteInformationen/eltern/"
                        className={classes.link}>
                        MEHR ERFAHREN
                        <div className={classes.buttonLink}>
                            <KeyboardArrowRightIcon className={classes.linkIcon}/>
                        </div>
                    </Link>
                </InfoBox>
            </InfoBar>
            <InfoTitle type="3" >
            </InfoTitle>
            <InfoBar wide="darkBlue">
                <InfoBox title="Rechtsicher" flexBox={false} icon={ <LocalLibraryIcon htmlColor="#6f6f6e" /> }>
                    {widthSize ? ""
                        :
                        <p> Mit <b className={classes.fehlzeitenerfassung}>fehlzeitenerfassung.de</b> ist es ein Leichtes Daten
                            gesetzeskonform zu verarbeiten. Die DSGVO-konforme
                            Vertragsgestaltung und Abwicklung Ihrer Datenverarbeitung
                            wird in jedem Schritt durch unsere Software unterstützt. </p>
                    }
                    <Link to="/info/sicherheit/rechtsicher"
                        className={classes.link}>
                        MEHR ERFAHREN
                        <div className={classes.buttonLink}>
                            <KeyboardArrowRightIcon className={classes.linkIcon}/>
                        </div>
                    </Link>
                </InfoBox>
                <InfoBox title="Geschützte Systeme" flexBox={false} icon={ <SecurityIcon htmlColor="#6f6f6e" /> }>
                    {widthSize ? ""
                        :
                        <p> Unsere Software ist nach höchsten Sicherheitsstandards
                            entwickelt. Zwei-Faktor Authentifizierung, verschlüsselter
                            Datenverkehr und tägliche Datensicherung sind bei uns ebenso
                            selbstverständlich wie die Speicherung in nach ISO 27001
                            zertifizierten Rechenzentren in Deutschland. </p>
                    }
                    <Link to="/info/sicherheit/security/"
                        className={classes.link}>
                        MEHR ERFAHREN
                        <div className={classes.buttonLink}>
                            <KeyboardArrowRightIcon className={classes.linkIcon}/>
                        </div>
                    </Link>
                </InfoBox>
                <InfoBox title="Zero-Knowledge-Verschlüsselung" flexBox={false} icon={ <LockIcon htmlColor="#6f6f6e" />}>
                    {widthSize ? ""
                        :
                        <p> Behalten Sie die Kontrolle über Ihre Daten – wir
                            verschlüsseln alle sensitiven Information direkt clientseitig
                            auf Ihrem Gerät: Potentielle Angreifer aber auch Dienstleister
                            und Administratoren haben keinen Einblick. </p>
                    }
                    <Link to="/info/sicherheit/zero-knowledge"
                        className={classes.link}>
                        MEHR ERFAHREN
                        <div className={classes.buttonLink}>
                            <KeyboardArrowRightIcon className={classes.linkIcon}/>
                        </div>
                    </Link>
                </InfoBox>
                <ColorButton href="/app/" >
                    SOFORT STARTEN
                </ColorButton>
            </InfoBar>

        </EntryLayout>
    );
};

export default IndexPage;
