import React from "react";
import ReactResizeDetector from "react-resize-detector";
import PropTypes from "prop-types";




import Typography from "@material-ui/core/Typography";
import globalStyles from "./styles";

import Main from "./main";
import Footer from "./footer";
import Logo from "./Logo";

const EntryLayout = ({ children }) => {
    const classes = globalStyles();
    const content =(
        <Main>
            <Logo />
            <div
                className={ classes.entryContainer }
            >
                <Typography
                    component="main"
                >
                    {children}
                </Typography>
                <Footer />
            </div>
        </Main>
    );

    return (
        <>
            {content}
        </>
    );
};

EntryLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default EntryLayout;
